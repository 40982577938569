import React, { FunctionComponent, useState } from 'react';
import axios from 'axios';
import { AccountRequestDTO } from '../../types';
import { useAuth } from '../../context/AuthContext';
import './AccountRequestForm.scss';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

interface IProps {
  handleCloseModal(): void;
  refreshData(): void;
}

const AccountRequestForm: FunctionComponent<IProps> = props => {
  const { accessToken } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [formData, setFormData] = useState<AccountRequestDTO>({} as AccountRequestDTO);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post<AccountRequestDTO>(`${process.env.REACT_APP_SERVICE_BASEPATH}/account-requests/`, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`, // Adding the Authorization header
          // Any other headers you need can go here
        },
      });
      console.log('Account Request Created:', response.data);
      props.refreshData();
      props.handleCloseModal();
      // Optionally, clear form or provide user feedback
    } catch (error) {
      console.error('Error creating account request:', error);
      // Handle error
    } finally {
      setIsSubmitting(false); // Reset submission state regardless of outcome
    }
  };

  return (
    <div className='account-request-form'>
      <h2>New Account Request</h2>
      <form onSubmit={handleSubmit}>
        <div className='form-item-wrapper'>
          <div className='form-item'>
            <label htmlFor="firstName">First Name</label>
            <input id="firstName" name="firstName" type="text" value={formData.firstName} onChange={handleChange} />
          </div>
          <div className='form-item'>
            <label htmlFor="lastName">Last Name</label>
            <input id="lastName" name="lastName" type="text" value={formData.lastName} onChange={handleChange} />
          </div>
          <div className='form-item'>
            <label htmlFor="accountNumber">CFR Account Number</label>
            <input id="accountNumber" name="accountNumber" type="text" value={formData.accountNumber} onChange={handleChange} />
          </div>
          <div className='form-item'>
            <label htmlFor="toEmail">To Email</label>
            <input id="toEmail" name="toEmail" type="email" value={formData.toEmail} onChange={handleChange} />
          </div>
          <div className='form-item'>
            <label htmlFor="toPhone">To Phone</label>
            <input id="toPhone" name="toPhone" type="tel" value={formData.toPhone} onChange={handleChange} />
          </div>
          <div className='form-item'>
        <label htmlFor="type">Initial Contact Type</label>
        <select id="type" name="type" value={formData.type} onChange={handleChange}>
        <option value="" selected disabled>Select one--</option>
          <option value="email">email</option>
          <option value="sms">sms</option>
        </select>
      </div>
          <div className='form-item'>
            <label htmlFor="note">Note</label>
            <textarea id="note" name="note" value={formData.note} onChange={handleChange} />
          </div>

        </div>
        <button className='submit-button icon' type="submit" disabled={isSubmitting}>Create Account Request <Icon path={mdiPlus} size={1} /></button>
      </form>
      {isSubmitting && <LoadingIndicator />}
    </div>
  );
};

export default AccountRequestForm;
