import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DataTableProps } from '../../../types'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';
import { formatTimestamp } from '../../../formatTimestamp';
import './DataTable.scss';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';

const DataTable: React.FC<DataTableProps> = ({ data, onSort, hasMore, fetchData }) => {

    const navigate = useNavigate();

    const [selectedRow, setSelectedRow] = useState<number>(-1);
    // Function to handle row click
    const handleRowClick = (id: number) => {
      navigate(`/details/${id}`);
      setSelectedRow(id);
    };

  return (
    <div className='data-table-wrapper' id='table-scroll-body'>
    <InfiniteScroll
      dataLength={data.length}
      next={fetchData}
      hasMore={hasMore}
      loader={
        <div className='table-loader'><LoadingIndicator /></div>
      }
      scrollableTarget="main-view"
    >
      <table className='data-table'>
        <thead>
          <tr>
            <th onClick={() => onSort('id')}>id</th>
            <th onClick={() => onSort('accountNumber')}>Account #</th>
            <th onClick={() => onSort('lastName')}>Last, First</th>
            <th onClick={() => onSort('toEmail')}>Email</th>
            <th onClick={() => onSort('toPhone')}>Phone</th>
            <th onClick={() => onSort('type')}>Type</th>
            <th onClick={() => onSort('status')}>Status</th>
            <th onClick={() => onSort('dateCreated')}>Date Created</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id} className={(selectedRow === item.id ? 'selected' : '')} onClick={() => handleRowClick(item.id)}>
              <td>{item.id}</td>
              <td>{item.accountNumber}</td>
              <td>{item.lastName}, {item.firstName}</td>
              <td>{item.toEmail}</td>
              <td>{item.toPhone}</td>
              <td>{item.type}</td>
              <td><span className={'tag-' + item.status}>{item.status}</span></td>
              <td>{formatTimestamp(item.dateCreated.toString())}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </InfiniteScroll>
    </div>
  );
};

export default DataTable;

