// formatTimestamp.ts

export const formatTimestampTime = (timestamp: string, locale: string = 'en-US', options: Intl.DateTimeFormatOptions = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit',
    hour12: true,
  }): string => {
    const date: Date = new Date(timestamp);
    return new Intl.DateTimeFormat(locale, options).format(date);
  };
  
  
export const formatTimestamp = (timestamp: string, locale: string = 'en-US', options: Intl.DateTimeFormatOptions = {
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour12: true,
}): string => {
  const date: Date = new Date(timestamp);
  return new Intl.DateTimeFormat(locale, options).format(date);
};

