import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import DataTable from './DataTable';
import SearchBar from './SearchBar';
import { AccountRequestDTO } from '../../types'; // Adjust the import path as necessary
import { useAuth } from '../../context/AuthContext';
import Modal from '../Modal';
import AccountRequestForm from '../AccountRequestForm';
import Icon from '@mdi/react';
import { mdiPlus, mdiRefresh  } from '@mdi/js';
import { debounce } from 'lodash';
import './AccountRequests.scss';

const AccountRequests: React.FC = () => {

  const { accessToken } = useAuth();
  const [resetToken, setResetToken] = useState(0);
  const [data, setData] = useState<AccountRequestDTO[]>([]);
  const [page, setPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('dateCreated');
  const [direction, setDirection] = useState<string>('desc');
  const [isModalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState<string|undefined>(); 
  const [version, setVersion] = useState<number>(0);

  const [isFetching, setIsFetching] = useState(false);

  const resetChild = () => {
    setSortBy('dateCreated');
    setDirection('desc');
    setResetToken(Date.now()); // Use current timestamp as a unique token

  };
  const refreshData = () => handleSearch(searchParams);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  
  const fetchData = async () => {
    if (isFetching) return; // Prevent fetching if already fetching
    setIsFetching(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVICE_BASEPATH}/account-requests/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { sortBy, direction, pageNumber: page, searchParams },
      });
      if (response.data.content.length === 0) {
        setHasMore(false);
      } else {
        setData(prevData => [...prevData, ...response.data.content]);
        setPage(prevPage => prevPage + 1);
        if (response.data.last === true) {
          setHasMore(false);
        }
      }
    } catch (error) {
      const axiosError = error as AxiosError; // Cast error to AxiosError
      setError(axiosError.message); // Use AxiosError message or handle differently
      console.error('Failed to fetch data:', axiosError.message);
    }  finally {
      setIsFetching(false); // Reset fetching state regardless of success or failure
    }
  };

  useEffect(() => {
    if (!error) { // Only fetch data if no error has occurred
      fetchData();
    }
  }, [searchParams, sortBy, direction, version]);

  // Create a debounced function
const debouncedSearch = debounce((searchQuery: string) => {
  setData([]);
  setPage(0);
  setSearchParams(searchQuery);
  setHasMore(true);
  setVersion(version + 1);
}, 300); // Adjust the timing based on your needs

  const handleSearch = (searchQuery: string): void => {
    debouncedSearch(searchQuery);
  };

  const handleSort = (newSortBy: string): void => {
    if (sortBy === newSortBy) {
      setDirection(direction === 'desc' ? 'asc' : 'desc');
    } else {
      setSortBy(newSortBy);
      setDirection('desc');
    }
    setData([]);
    setPage(0);
    setHasMore(true);
  };

  return (
    <div className='account-requests'>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <AccountRequestForm handleCloseModal={handleCloseModal} refreshData={refreshData} />
      </Modal>
      <div className='account-requests-options'>
        <SearchBar resetToken={resetToken} onSearch={handleSearch} />
        <div className='button-area'>
        <button className='icon' onClick={resetChild}><Icon path={mdiRefresh} size={1} />Reset</button>
        <button className='icon' onClick={handleOpenModal}>New Request <Icon path={mdiPlus} size={1} /></button>
        </div>
      </div>
      <DataTable data={data} onSort={handleSort} hasMore={hasMore} fetchData={fetchData} />
    </div>
  );
};

export default AccountRequests;
