import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import AccountRequests from "./components/AccountRequests";
import LogoutButton from "./components/LogoutButton";

import { useAuth } from './context/AuthContext';
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import AccountRequestDetails from "./components/AccountRequests/Details/AccountRequestDetails";
import AccountVerification from "./components/AccountVerification";

import './App.scss';
import AccountRequestsLayout from "./components/AccountRequests/AccountRequestsLayout";
import AppHeader from "./components/AppHeader/AppHeader";
import { useConfigureAxios } from "./useAxiosConfig";
import { msalInstance } from "./authConfig";
// Remember that MsalProvider must be rendered somewhere higher up in the component tree
function App() {

  

  const { accessToken, setAccessToken } = useAuth();


  const AxiosConfigurator: FC = () => {
    useConfigureAxios(); // Setup Axios interceptors
    return null; // This component doesn't render anything
  };
  
  // const forceLogout = () => {
  //   // Optional: You might want to clear application-specific state before logging out
  
  //   instance.logoutPopup({
  //     postLogoutRedirectUri: "/", // Specify where to redirect after logging out, if needed
  //   }).catch(e => {
  //     console.error("Error during logout:", e);
  //   });
  
  //   // For redirect logouts, you can use:
  //   // msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
  // };


  // useEffect(() => {
  //   const request = {
  //     // Replace "User.Read" with the scope you need for the access token
  //     scopes: ["https://springsdev.onmicrosoft.com/18f4aa28-a920-431e-9132-2bd105962975/User"],
  //     account: accounts[0],
  //     maxAge: 3600
  //   };

  //   // Fetch the access token
  //   instance.acquireTokenSilent(request).then((response) => {
  //     setAccessToken(response.accessToken);
  //     sessionStorage.setItem('accessToken', response.accessToken);
  //   }).catch((e) => {
  //     // If there is an error or the silent token acquisition fails, use popup or redirect
  //     if (e.name === 'InteractionRequiredAuthError') {
  //       instance.acquireTokenPopup(request).then((response) => {
  //         setAccessToken(response.accessToken);
  //         sessionStorage.setItem('accessToken', response.accessToken);
  //       });
  //     }
  //   });
  // }, [instance, accounts]);




  return (
    
    <div id="modal-root" className="main-wrapper">
      <AxiosConfigurator />
    
      <div className="header-wrapper">
        <AppHeader />
      </div>
      <div className="body-wrapper">
      {accessToken && 
        <BrowserRouter>
      <Routes>
        <Route path="/" element={<AccountRequestsLayout />}>
          <Route path="details/:id" element={<AccountRequestDetails key={Date.now()} />} />
        </Route>
      </Routes>
    </BrowserRouter>

       
      }
      </div>
      
      
    
    </div>
  );
}

export default App;