import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import ReactDOM from 'react-dom';

const Modal: React.FC<{ isOpen: boolean; onClose: () => void; children: React.ReactNode }> = ({
  isOpen,
  onClose,
  children,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div style={{ position: 'fixed', zIndex : 100, top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ position: 'relative', background: 'white', padding: '20px', borderRadius: '5px', maxWidth: '500px', width: '100%' }}>
        <button className='close-button' onClick={onClose} style={{ cursor: 'pointer', border: 0, backgroundColor: 'transparent', position: 'absolute', top: '1rem', right: '1rem', 'zIndex': 100 }}><Icon path={mdiClose} size={1} /></button>
        
        {children}
      </div>
    </div>,
    document.getElementById('modal-root')!
  );
};


export default Modal;