// MainLayout.jsx

import React from 'react';
import { Outlet } from 'react-router-dom';
import AccountRequests from './AccountRequests';

import './AccountRequests.scss';

const AccountRequestsLayout = () => {
  return (
    <div className='account-requests-layout'>
      <div className='main-view' id="main-view">
        <AccountRequests />
      </div>
      <div className='detail-view'>
        <Outlet />
      </div>
    </div>
  );
};

export default AccountRequestsLayout;
