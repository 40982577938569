import React from 'react';
import { useMsal } from '@azure/msal-react';
import { useAuth } from '../../context/AuthContext';
import LogoutButton from '../LogoutButton';

import './AppHeader.scss';

const AppHeader: React.FC = () => {
    const { accessToken } = useAuth();

  

  return (
    <div className='app-header'>
      <img className='logo' src="https://images.squarespace-cdn.com/content/v1/5e0f65245425f3307a8ba190/1610141473546-Y7JTPTT2J9EOVSSTUQ1U/static1.squarespace-2.png?format=1500w" />
        <LogoutButton />
    </div>
  );
};

export default AppHeader;