import { useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './context/AuthContext';
import { useMsal } from '@azure/msal-react';

export const useConfigureAxios = () => {
  const { accessToken, acquireToken } = useAuth();
  const { instance, accounts, inProgress } = useMsal();
  
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(async config => {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    }, error => Promise.reject(error));

    const responseInterceptor = axios.interceptors.response.use(response => response, async error => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        sessionStorage.clear();
        localStorage.clear();
      }
      return Promise.reject(error);
    });

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [accessToken, acquireToken]);
};
