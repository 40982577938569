import React from 'react';
import { useMsal } from '@azure/msal-react';


const LogoutButton = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType = "redirect") => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/", // Specify where to redirect after a successful logout (for popup)
      });
    } else {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/", // Specify where to redirect after a successful logout (for redirect)
      });
    }
  };

  return (
    <button className='logout' onClick={() => handleLogout("redirect")}>Logout</button>
  );
};

export default LogoutButton;
