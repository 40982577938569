import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { msalInstance } from '../authConfig';

interface AuthContextType {
  accessToken: string | null;
  setAccessToken: (token: string | null) => void;
  acquireToken: () => Promise<void>; // Add method for acquiring token
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);



interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [accessToken, setAccessToken] = useState<string | null>(sessionStorage.getItem('accessToken'));
  const [error, setError] = useState<string|null>();

  useEffect(() => {
    if (accessToken) {
      sessionStorage.setItem('accessToken', accessToken);
    } else {
      sessionStorage.removeItem('accessToken');
    }
  }, [accessToken]);

  // Function to acquire token
  const acquireToken = async () => {
    console.log('trying to get token in auth context');
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        const response = await msalInstance.acquireTokenSilent({
          account: accounts[0],
          scopes: [process.env.REACT_APP_AD_SCOPE!]
        });
        setAccessToken(response.accessToken);
      }
    } catch (error) {
      setError("Error acquiring access token");
      console.error("Error acquiring access token:", error);
      setAccessToken(null); // Reset token on failure
      // Optional: handle forcing logout or redirecting to login here
    }
  };

  // Attempt to acquire token on mount
  useEffect(() => {
    if (!accessToken && !error) {
      acquireToken();
    }
  }, []);

  const value = { accessToken, setAccessToken, acquireToken };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
