import React, { ChangeEvent, useEffect, useState } from 'react';
import { SearchBarProps } from '../../../types'; // Adjust the import path as necessary

import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import './SearchBar.scss';

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, resetToken }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  function debounce<T extends any[]>(func: (...args: T) => void, delay: number): (...args: T) => void {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;
    return (...args: T) => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => func(...args), delay);
    };
  }
  

  // Use `React.useCallback` to memorize the debounced function
  const debouncedSearch = React.useCallback(debounce((query: string) => {
    fetchData(query);
  }, 500), []); // Empty dependency array to ensure stability across renders

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm, debouncedSearch]); // Include `debouncedSearch` to adhere to exhaustive-deps rule

  const fetchData = (query: string) => {
    onSearch(query);
    // Implement your data fetching logic here
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setSearchTerm('');
    fetchData('');
  }, [resetToken]);

  return (
    <div className='search-bar'>
      <Icon className='search-icon' path={mdiMagnify} size={1} />
    <form>
      <input
        autoFocus 
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search..."
      />
    </form>
    </div>
  );
};

export default SearchBar;
